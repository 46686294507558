<template>
  <section class="boardWrap">
    <navi-bar>
      {{$t('front.mypage.betList')}}
    </navi-bar>
    <div class="moneytab">
      <a><span>{{$t('front.gameCategory.all')}}</span></a>
      <template v-for="item in gameGroupCodes" v-bind:key="item.groupCode" >
        <a :class="{'on': type == item.groupCode}" @click="type = item.groupCode">
          <span>{{$i18n.locale !== 'ko' ? item[`groupCodeName${$i18n.locale.toUpperCase()}`] : item.groupCodeName}}</span>
        </a>
      </template>
    </div>

    <div class="writebtn2 mt10 mb10">
      <select class="select" v-model="vendorKey">
        <template v-for="item in commonCode[type]" v-bind:key="item.code">
          <option :value="item.code">
            {{$i18n.locale !== 'ko' ? item[`codeName${$i18n.locale.toUpperCase()}`] : item.codeName}}
          </option>
        </template>
      </select>
    </div>

    <date-filter-mobile @search="loadList(1)"
                        @update="onChangeDate"
                        :startDate="searchDate.startDate"
                        :endDate="searchDate.endDate" :isOldYn="true"
                        @setOldYn="setOldYn" :oldYn="oldYn" />

    <div class="gamebetwrap mb100">
      <template v-if="betList.length > 0">
        <template v-for="item in betList" v-bind:key="item.betIdx">
          <div class="gamebetlist">
            <ul class="gamebetdetail2">
              <li><span class="ft12">{{dateFormatAll(item.createdAt)}}</span><!--a><img src="@/assets_mobile/img/icon_arrowR.png" /></a--></li>
              <li>
              <span>
                <em :class="item.betCalResults">
                  {{$t('front.bettingStatus.' + item.betCalResults)}}
                </em>
                {{$i18n.locale !== 'ko' ? item[`vendorName${$i18n.locale.toUpperCase()}`] : item.vendorName}}
                ({{item.gameType}})
              </span>
              </li><!-- win lose bet -->
              <li class="ft12">
                <span>{{$t('front.board.gameType')}}</span>
                <span>{{item.gameType}}</span>
              </li>
              <li>
                <span>{{$t('front.board.betMoney')}}</span>
                <span>{{thousand(item.cash)}}</span>
              </li>
              <li class="ft13">
                <span>{{$t('front.board.revenue')}}</span>
                <span>{{thousand(item.resultCash)}}</span>
              </li>

              <li class="ft13 gr">
                <span>{{$t('front.board.gameMoney')}}</span>
                <span>{{thousand(item.afterCash)}} {{$t('front.common.money')}}</span>
              </li>
            </ul>
          </div>
        </template>
      </template>
      <template v-else>
        <span class="nobettxt">{{$t('front.board.noBet')}}</span>
      </template>
    </div>

    <pagination-mobile :pageNum="pageInfo.page"
                       :pageSize="pageInfo.count_per_list"
                       :totalCount="pageInfo.tatal_list_count"
                       @goToPage="pageChangeMobile"
    />
  </section>
</template>

<script>
import NaviBar from '@/components/common/mobile/NaviBar'

import bettingList from '@/views/member/mypage/bet/bettingList'
import DateFilterMobile from '@/components/ui/DateFilterMobile'
import PaginationMobile from '@/components/ui/PaginationMobile'
export default {
  name: 'betList',
  mixins: [bettingList],
  components: {
    NaviBar,
    DateFilterMobile,
    PaginationMobile
  },
  methods: {
    async pageChangeMobile (page) {
      const list = await this.onBettingList(page, this.vendorKey, 'mobile', this.oldYn)
      this.betList = this.betList.concat(list)
    }
  }
}

</script>

<style scoped src="@/styles_mobile/common.css"></style>
<style scoped src="@/styles_mobile/subcommon.css"></style>
<style scoped src="@/styles_mobile/board.css"></style>
<style scoped src="@/styles_mobile/betlist.css"></style>

<style>
.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  transition: opacity 0.5s ease-in;
}

.fade-leave-active {
  transition: opacity 0.5s ease-in;
}

.fade-leave-to {
  opacity: 0;
}

.slide-fade-enter {
  transform: translateX(10px);
  opacity: 0;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.2s ease;
}

.slide-fade-leave-to {
  transform: translateX(-10px);
  opacity: 0;
}

.slide-up-enter {
  transform: translateY(185px);
  opacity: 0;
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.3s ease;
}

.slide-up-leave-to {
  transform: translateY(0px);
  opacity: 0;
}
</style>
